import { reducer as userReducer } from './User'
import { reducer as alertReducer, AlertState } from './Alert'
import { TiprUser } from '../services/userService'

export interface ApplicationState {
  user: TiprUser | undefined
  alert: AlertState
}

export const reducers = {
  user: userReducer,
  alert: alertReducer
}

export interface AppThunkAction<TAction> {
  (dispatch: (action: TAction) => void, getState: () => ApplicationState): void
}
