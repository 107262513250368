import React from 'react'
import './Dashboard.css'
import { Link } from 'react-router-dom'
import { Grid, Header, Menu, Popup } from 'semantic-ui-react'
import { useLocation } from 'react-router'
import { TiprUser } from '../services/userService'

interface DashboardProps {
	title: string
	children?: React.ReactNode
}

const Dashboard_rep: React.FC<DashboardProps> = ({
	children = null,
	title
}: DashboardProps) => {
	const { pathname } = useLocation()

	const userJson = localStorage.getItem('user') || ''
	const user: TiprUser = JSON.parse(userJson)
	// 2 = Manna, 18 = Microcon
	const isManna = user.companyId === 2
	const isBB = user.companyId === 14
	const isCov = user.companyId === 4
	const isSupport = user.companyId === 18
	const isMMR = user.companyId === 33

	return (
		<Grid padded>
			<Grid.Column tablet={2} computer={2} only='tablet computer' id='sidebar'>
				<Menu vertical borderless fluid text>
					<Menu.Item name='Home' active={pathname === '/'} as={Link} to='/' />

					{/*Manna*/}
					<Menu.Item
						as={!(isSupport || isManna) ? 'a' : Link}
						to={!(isSupport || isManna) ? '#' : '/tipOrders'}
						disabled={!(isSupport || isManna)}
						active={pathname === '/tipOrders'}
					>
						3PA Tips
					</Menu.Item>

					<Menu.Item
						as={!(isSupport || isManna) ? 'a' : Link}
						to={!(isSupport || isManna) ? '#' : '/orderLookup'}
						disabled={!(isSupport || isManna)}
						active={pathname === '/orderLookup'}
					>
						3PA Order Lookup
					</Menu.Item>

					<Menu.Item
						as={!(isSupport || isManna) ? 'a' : Link}
						to={!(isSupport || isManna) ? '#' : '/TipsWorkstation'}
						disabled={!(isSupport || isManna)}
						active={pathname === '/TipsWorkstation'}
					>
						Employee Hours and Tips
					</Menu.Item>

					<Menu.Item
						as={!(isSupport || isManna) ? 'a' : Link}
						to={!(isSupport || isManna) ? '#' : '/MannaEmployeeHoursAndTipsExport'}
						disabled={!(isSupport || isManna)}
						active={pathname === '/MannaEmployeeHoursAndTipsExport'}
					>
						Emp Hrs And Tips Export (total for pay period)
					</Menu.Item>

					<Menu.Item
						as={!(isSupport || isManna || isMMR) ? 'a' : Link}
						to={!(isSupport || isManna || isMMR) ? '#' : '/TeamTipExport'}
						disabled={!(isSupport || isManna || isMMR)}
						active={pathname === '/TeamTipExport'}
					>
						Team Tip Export
					</Menu.Item>

					<Menu.Item
						as={!(isSupport || isManna) ? 'a' : Link}
						to={!(isSupport || isManna) ? '#' : '/MannaTipBatching'}
						disabled={!(isSupport || isManna)}
						active={pathname === '/MannaTipBatching'}
					>
						Catering Tips Export
					</Menu.Item>

					<Menu.Item
						as={!(isSupport || isManna) ? 'a' : Link}
						to={!(isSupport || isManna) ? '#' : '/tipsAllocationSummary'}
						disabled={!(isSupport || isManna)}
						active={pathname === '/tipsAllocationSummary'}
					>
						Catering Tip Allocation Summary
					</Menu.Item>

					<Menu.Item
						as={!(isSupport || isManna) ? 'a' : Link}
						to={!(isSupport || isManna) ? '#' : '/OrderDetails'}
						disabled={!(isSupport || isManna)}
						active={pathname === '/OrderDetails'}
					>
						Cafe Orders
					</Menu.Item>

					<Menu.Item
						as={!(isSupport || isManna) ? 'a' : Link}
						to={!(isSupport || isManna) ? '#' : '/MannaGLExport'}
						disabled={!(isSupport || isManna)}
						active={pathname === '/MannaGLExport'}
					>
						3PA GL Extract
					</Menu.Item>

					<Menu.Item
						as={!(isSupport || isManna) ? 'a' : Link}
						to={!(isSupport || isManna) ? '#' : '/MannaOnAccountExtract'}
						disabled={!(isSupport || isManna)}
						active={pathname === '/MannaOnAccountExtract'}
					>
						OnAccount Extract
					</Menu.Item>

					{/*TRG and BB*/}

					<Menu.Item
						as={!(isSupport) ? 'a' : Link}
						to={!(isSupport) ? '#' : '/trgTipReport'}
						disabled={!(isSupport)}
						active={pathname === '/trgTipReport'}
					>
						Tip Report
					</Menu.Item>

					<Menu.Item
						as={!(isSupport) ? 'a' : Link}
						to={!(isSupport) ? '#' : '/BBCashFileExport'}
						disabled={!(isSupport)}
						active={pathname === '/BBCashFileExport'}
					>
						Sales And Cash Export
					</Menu.Item>

					{/*Covelli*/}

					<Menu.Item
						as={!(isSupport || isCov) ? 'a' : Link}
						to={!(isSupport || isCov) ? '#' : '/HarriJobPostings'}
						disabled={!(isSupport || isCov)}
						active={pathname === '/HarriJobPostings'}
					>
						Harri/BalanceTrak Job Postings
					</Menu.Item>
				</Menu>
			</Grid.Column>



			<Grid.Column
				mobile={16}
				tablet={14}
				computer={14}
				floated='right'
				id='content'
			>
				<Grid padded>
					<Grid.Row>
						<Header dividing size='huge' as='h1'>
							{title}
						</Header>
					</Grid.Row>

					<Grid.Row>{children}</Grid.Row>
				</Grid>
			</Grid.Column>
		</Grid>
	)
}

export default Dashboard_rep
