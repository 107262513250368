import React, { useState, useEffect } from 'react'
import { Segment, Dimmer, Loader, Table, Button } from 'semantic-ui-react'
import batchService, { Batch } from '../services/batchService'
import FileSaver from 'file-saver'
import moment from 'moment'

// The update prop just listens for changes to trigger re-fetching batches
const Batches: React.FC<{update: number}> = (props) => {
  const [batches, setBatches] = useState([] as Batch[])
  const [loaded, setLoaded] = useState(false)

  const { update } = props

  useEffect(() => {
    batchService
      .getAllBatches()
      .then(batches => {
        setBatches(batches || [] as Batch[])
        setLoaded(true)
      })
  }, [update])

  const handleDownloadClick = (id: number) => async () => {
    const result = await batchService.getExportedBatch(id)
    if (result) {
      const blob = new Blob([result.data], { type: 'octet/stream' })
      FileSaver.saveAs(blob, result.fileName)
    } else {
      console.error('an error occured while dowloading file')
    }
  }

  return (
    <Segment style={{ overflow: 'auto', maxHeight: '55vh', padding: 0 }} size="small">
      <Dimmer active={!loaded}>
        <Loader />
      </Dimmer>
      <Table singleLine striped selectable unstackable compact style={{ marginTop: 0 }}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Download</Table.HeaderCell>
            <Table.HeaderCell>Batch Number</Table.HeaderCell>
            <Table.HeaderCell>Company</Table.HeaderCell>
            <Table.HeaderCell>Cutoff Date</Table.HeaderCell>
            <Table.HeaderCell>Batch Total</Table.HeaderCell>
            <Table.HeaderCell>Date Created</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {batches.map(
            (batch): JSX.Element => (
              <Table.Row key={batch.id}>
                <Table.Cell><Button onClick={handleDownloadClick(batch.id)} icon="download" /></Table.Cell>
                <Table.Cell>{batch.id}</Table.Cell>
                <Table.Cell>{batch.company}</Table.Cell>
                <Table.Cell>{moment(batch.cutoffDate).format('MM/DD/YYYY')}</Table.Cell>
                <Table.Cell>{batch.batchTotal}</Table.Cell>
                <Table.Cell>{moment(batch.dateCreated).format('MM/DD/YYYY')}</Table.Cell>
              </Table.Row>
            )
          )}
        </Table.Body>
      </Table>
    </Segment>
  )
}

export default Batches
