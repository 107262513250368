import React, { RefObject } from 'react'
import { Grid, Header, Form, Segment, Table } from 'semantic-ui-react'
import FileSaver from 'file-saver'
import './Dashboard.css'
import config from '../utils/config'
import Axios from 'axios'
import 'react-datepicker/dist/react-datepicker.css'
import { isNullOrUndefined } from 'util'
import ReactDatePicker from 'react-datepicker'

enum State {
  Uploading = 'Uploading',
  Validating = 'Validating',
  Processing = 'Processing',
  Formatting = 'Formatting',
  Success = 'Success',
  Error = 'Error'
}

class Task {
  name: string;

  promise: Promise<Blob>;

  state: State;

  result: Blob | string;

  constructor (name: string, promise: Promise<Blob>, setTasks: (convert: (t: Task[]) => Task[]) => void) {
    this.name = name
    this.state = State.Uploading
    this.result = ''
    this.promise = promise
    promise.then((res: Blob) => {
      this.state = State.Success
      this.result = res
      return res
    }, (reason: Error) => {
      this.state = State.Error
      this.result = reason.message
    }).finally(() => { setTasks((tasks: Task[]): Task[] => [...tasks]) })
  }
}

class Mode {
  name: string;

  inputs: string[];

  outputs: number;

  constructor (name: string, inputs: string[], outputs: number) {
    this.name = name
    this.inputs = inputs
    this.outputs = outputs
  }
}

class AggregateConversion extends React.Component {
  public state: {
    tasks: Task[]
    modes: { [modeName: string]: Mode }
    files: { [filename: string]: RefObject<HTMLInputElement> }
    curMode: string
    startDate: Date
    endDate: Date
  } = {
    tasks: [],
    modes: {
      'Loading...': new Mode('Loading...', [], 0)
    },
    files: {},
    curMode: 'Loading...',
    startDate: ((date: Date) => { date.setDate(1); return date })(new Date()),
    endDate: ((date: Date) => { date.setMonth(date.getMonth() + 1); date.setDate(0); return date })(new Date())
  };

  componentDidMount () {
    Axios.get<{ [modeName: string]: Mode }>(`${config.API_BASE_URL}/ConvertAggregate`).then(response => {
      const mode: string = Object.keys(response.data)[0]
      this.setState({ modes: response.data, curMode: mode, files: this.makeFileMap(response.data[mode].inputs) })
    })
  }

  handleFileUpload (ev: React.MouseEvent<HTMLElement>): void {
    const formData: FormData = new FormData()
    const fileNames: string[] = this.state.modes[this.state.curMode].inputs
    for (let i = 0; i < fileNames.length; i++) {
      if (isNullOrUndefined(this.state.files[fileNames[i]].current!.files![0])) {
        console.error(`Missing file ${fileNames[i]}`)
        alert(`Missing file ${fileNames[i]}`)
        return
      }
      formData.append('files', this.state.files[fileNames[i]].current!.files![0])
    }
    const api = `${config.API_BASE_URL}/ConvertAggregate/${this.state.curMode}?startDate=${this.state.startDate}&endDate=${this.state.endDate}&noSaveDB=true`
    const promise: Promise<Blob> = Axios.post<Blob>(api, formData, { responseType: 'blob' }).then(response => {
      return response.data
    })
    const newTask: Task = new Task(this.state.curMode, promise, (callback: (t: Task[]) => Task[]) => {
      this.setState({ tasks: callback(this.state.tasks) })
    })
    this.setState({ tasks: [...this.state.tasks.filter(task => { return task.name != this.state.curMode }), newTask] })
  }

  renderResult (task: Task): JSX.Element {
    if (task.state == State.Success) {
      return (<button onClick={() => FileSaver.saveAs((task.result as Blob), task.name)}>Download</button>)
    } else if (task.state == State.Error) {
      return (<span className="error">{task.result}</span>)
    } else {
      return (<br />)
    }
  }

  makeFileMap (inputs: string[]): { [filename: string]: RefObject<HTMLInputElement> } {
    const fileMap: { [filename: string]: RefObject<HTMLInputElement> } = {}
    for (let i = 0; i < inputs.length; i++) {
      fileMap[inputs[i]] = React.createRef<HTMLInputElement>()
    }
    return fileMap
  }

  setCurrentMode (modeName: string) {
    this.setState({ files: this.makeFileMap(this.state.modes[modeName].inputs), curMode: modeName })
  }

  toColumnCount (n: number): 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15 | 16 {
    return Math.min(Math.max(Math.round(n), 1), 16) as 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15 | 16
  }

  render () {
    return (
      <Form>
        <Grid padded verticalAlign='middle'>
          <Grid.Row>
            <Grid.Column>
              <div id="container">
                <Header dividing size="huge">
                                    Report Conversion
                </Header>
              </div>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={4}>
            <Grid.Column width={5}>
              <Form.Field>
                <label htmlFor="curMode">Mode</label>
                <select className="form-control" id="curMode" onChange={e => this.setCurrentMode(e.target.value)}>
                  {Object.keys(this.state.modes).map((name: string) => (<option key={name}>{name}</option>))}
                </select>
              </Form.Field>
            </Grid.Column>
            <Grid.Column>
              <Form.Field>
                <label htmlFor="startDate">Start Date</label>
                <ReactDatePicker className="form-control" id="startDate" selected={this.state.startDate}
                  onChange={(d: Date) => this.setState({ startDate: d })} />
              </Form.Field>
            </Grid.Column>
            <Grid.Column>
              <Form.Field>
                <label htmlFor="endDate">End Date</label>
                <ReactDatePicker className="form-control" id="endDate" selected={this.state.endDate}
                  onChange={(d: Date) => this.setState({ endDate: d })} />
              </Form.Field>
            </Grid.Column>
            <Grid.Column width={1}>
              <Form.Field>
                <label style={{ visibility: 'hidden' }}>Padding</label>
                <button className="ui button" onClick={(e: React.MouseEvent<HTMLElement>) => this.handleFileUpload(e)}>Submit</button>
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={this.toColumnCount(this.state.modes[this.state.curMode].inputs.length)}>
            {this.state.modes[this.state.curMode].inputs.map((filename: string) => (
              <Grid.Column key={filename}>
                <Form.Field>
                  <label htmlFor={filename + '-input'}>{filename}</label>
                  <input type="file" className="ui form-control" name={filename} id={filename + '-input'} ref={this.state.files[filename]}
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" />
                </Form.Field>
              </Grid.Column>
            ))}
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Segment style={{ overflow: 'auto', maxHeight: '55vh', padding: 0 }} size="small">
                <Table singleLine striped selectable unstackable compact style={{ marginTop: 0 }}>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Filename</Table.HeaderCell>
                      <Table.HeaderCell>State</Table.HeaderCell>
                      <Table.HeaderCell>Result</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {this.state.tasks.map(
                      (task: Task) => (
                        <Table.Row key={task.name}>
                          <Table.Cell>{task.name}</Table.Cell>
                          <Table.Cell>{task.state}</Table.Cell>
                          <Table.Cell>{this.renderResult(task)}</Table.Cell>
                        </Table.Row>
                      )
                    )}
                  </Table.Body>
                </Table>
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
    )
  }
}
export default AggregateConversion
