import Axios from 'axios'
import config from '../utils/config'
import authHelper from '../utils/authHelper'
import handleResponseAuthorization from '../utils/responseHelper'

const baseUrl = config.API_BASE_URL

export interface PaginatedList<T> {
  pageNumber: number
  totalPages: number
  data: T[]
  hasPreviousPage: boolean
  hasNextPage: boolean
}

// This is a PaneraTipOrder now, nobody is using the UberTipOrders anymore and thus there is no need for CombinedTipOrders
export interface TipOrder {
  id: number
  businessDate: string
  cafeNumber: string
  cafeName: string
  state: string
  aggregator: string
  orderTime: Date
  posOrderNumber: number
  ecommOrderNumber: number
  externalOrderNumber: string
  driverEmployeeNumber: string
  driverName: string
  taxAmount: number
  tips: number
  totalAmount: number
  deliveryFee: number
  saleAmount: number
  companyId: number
  status: string
  orderType: string
  tax1: number
  tax2: number
  tax3: number
  tax4: number
  tax5: number
  tax6: number
  tax7: number
  tax8: number
  tax9: number
  tax10: number
}

export interface TipOrderSearchCriteria {
  startDate?: Date
  endDate?: Date
  aggregator?: string
  employeeId?: number
  firstName?: string
  lastName?: string
  pageNumber?: number
  aggrOrderId?: string
  panOrderId?: number
  storeNumber?: string
}

const defaultTipOrderList: PaginatedList<TipOrder> = {
  pageNumber: 0,
  totalPages: 0,
  data: [],
  hasPreviousPage: false,
  hasNextPage: false
}

const getAllTipOrders = (): Promise<PaginatedList<TipOrder>> => {
  return Axios.get<PaginatedList<TipOrder>>(`${baseUrl}/tiporder`, {
    headers: authHelper.authHeader()
  })
    .then(response => response)
    .then(result => {
      return result.data
    })
    .catch(error => {
      handleResponseAuthorization(error)
      throw error
    })
}

const combineAvailableSearchParams = (
  searchCriteria: TipOrderSearchCriteria
) => {
  const nameValuePairs = [
    {
      name: 'startDate',
      value: searchCriteria.startDate ? searchCriteria.startDate.toJSON() : null
    },
    {
      name: 'endDate',
      value: searchCriteria.endDate ? searchCriteria.endDate.toJSON() : null
    },
    { name: 'aggregator', value: searchCriteria.aggregator },
    { name: 'employeeId', value: searchCriteria.employeeId },
    { name: 'firstName', value: searchCriteria.firstName },
    { name: 'lastName', value: searchCriteria.lastName },
    { name: 'pageNumber', value: searchCriteria.pageNumber },
    { name: 'aggregatorOrderNumber', value: searchCriteria.aggrOrderId },
    { name: 'orderNumber', value: searchCriteria.panOrderId },
    {
      name: 'storeNumber',
      value:
        searchCriteria.storeNumber === 'All' ? 0 : searchCriteria.storeNumber
    }
  ]
  // Builds query string by removing any pairs with no value and then combining into one query string.
  // Query strings are in the form of `name1=value1&name2=value2...`
  return nameValuePairs
    .filter(entry => entry.value)
    .map(entry => `${entry.name}=${entry.value}`)
    .join('&')
}

const searchTipOrders = (
  searchCriteria: TipOrderSearchCriteria
): Promise<PaginatedList<TipOrder>> => {
  const urlParams = combineAvailableSearchParams(searchCriteria)

  // no valid search parameters, do regular search
  // might want to return an error instead?
  const url = urlParams
    ? `${baseUrl}/tipOrder?${urlParams}`
    : `${baseUrl}/tipOrder`
  return Axios.get<PaginatedList<TipOrder>>(url, {
    headers: authHelper.authHeader()
  })
    .then(response => response)
    .then(result => {
      console.log(result.data)
      return result.data
    })
    .catch(error => {
      console.log('caught in service')
      console.log(error)
      handleResponseAuthorization(error)
      throw error
    })
}

const getOneTipOrder = async (id: number) => {
  try {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const result = await Axios.get<TipOrder>(`${baseUrl}/tiporder/${id}`, {
      headers: authHelper.authHeader()
    })
    return result.data
  } catch (error) {
    handleResponseAuthorization(error)
  }
}

const exportQuery = async (searchCriteria: TipOrderSearchCriteria) => {
  const urlParams = combineAvailableSearchParams(searchCriteria)

  const url: string = urlParams
    ? `${baseUrl}/tiporder/ExportSearch?${urlParams}`
    : `${baseUrl}/tiporder/ExportSearch`

  try {
    const response = await Axios.get(`${url}`, {
      responseType: 'arraybuffer',
      headers: authHelper.authHeader()
    })
    const fileName = response.headers['x-tipr-filename']
    const fileType = response.headers['content-type']
    return { fileName, data: response.data, fileType }
  } catch (error) {
    handleResponseAuthorization(error)
    return Promise.reject(error)
  }
}

export default {
  getAllTipOrders,
  getOneTipOrder,
  searchTipOrders,
  exportQuery,
  defaultTipOrderList
}
