import Axios from 'axios'
import config from '../utils/config'
import authHelper from '../utils/authHelper'
import handleResponseAuthorization from '../utils/responseHelper'

const baseUrl = config.API_BASE_URL

export interface FileImport {
  id: number
  fileName: string
  type: string
  action: string
  message: string
  dateCreated: Date
  fileTimestamp: Date
}

const getLastFiveFilesImported = () => {
  return Axios.get<FileImport[]>(`${baseUrl}/fileImports/lastFive`, { headers: authHelper.authHeader() })
    .then(result => result)
    .then(result => {
      console.log(result.data)
      return result.data
    })
    .catch(error => {
      handleResponseAuthorization(error)
      return []
    })
}

export default {
  getLastFiveFilesImported
}
