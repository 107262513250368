import Axios, { AxiosResponse } from 'axios'
import handleResponseAuthorization from '../utils/responseHelper'
import config from '../utils/config'
import authHelper from '../utils/authHelper'

const baseUrl = config.API_BASE_URL

export interface PayWeek {
  weekStart: string
  weekEnd: string
}

interface PayWeeksResponse {
  data: PayWeek[]
  errorMessage: string
}

const getPayWeeksForDropdown = () => {
  return Axios.get<PayWeek[]>(`${baseUrl}/PayWeek`, {
    headers: authHelper.authHeader()
  })
    .then((response: AxiosResponse<PayWeek[]>) => {
      console.log('payWeekService: ', response.data)
      if (!response.data) {
        throw new Error('No pay week data received.')
      }
      return response.data
    })
    .catch(error => {
      handleResponseAuthorization(error)
      throw error
    })
}

export default {
  getPayWeeksForDropdown
}
