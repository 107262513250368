import React from 'react'
import { Divider, Grid, Header, Menu, Button } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import reportService from '../services/reportService'

const Reports: React.FC = () => (
  <Grid padded>
    <Grid.Column tablet={3} computer={3} only="tablet computer" id="sidebar">
      <Menu vertical borderless fluid text>
        <Menu.Item as={Link} to="/dashboard">
          Overview
        </Menu.Item>
        <Menu.Item active as={Link} to="/reports">
          Reports
        </Menu.Item>
        <Menu.Item as={Link} to="/exports">Export</Menu.Item>
      </Menu>
    </Grid.Column>
    <Grid.Column
      mobile={16}
      tablet={13}
      computer={13}
      floated="right"
      id="content"
    >
      <Grid padded>
        <Grid.Row>
          <Header dividing size="huge" as="h1">
            Dashboard
          </Header>
        </Grid.Row>
        <Divider section hidden />
        <Grid.Row>
          <Header dividing size="huge" as="h2">
            Reports
          </Header>
        </Grid.Row>
        <Grid.Column computer={7}>
          <Grid>
            <Grid.Row>
              <Button fluid size="big" as={Link} to="/discrepanciesReport">
                Discrepancies
              </Button>
            </Grid.Row>
            {reportService.getAvailableReports().map(report => (
              <Grid.Row key={report.id}>
                <Button fluid size="big">
                  {report.name}
                </Button>
              </Grid.Row>
            ))}
          </Grid>
        </Grid.Column>
      </Grid>
    </Grid.Column>
  </Grid>
)

export default Reports
