import Axios from 'axios'
import config from '../utils/config'
import authHelper from '../utils/authHelper'
import handleResponseAuthorization from '../utils/responseHelper'

const baseUrl = config.API_BASE_URL

export interface PaginatedList<T> {
  pageNumber: number
  totalPages: number
  data: T[]
  hasPreviousPage: boolean
  hasNextPage: boolean
}

export interface TipWorkstations {
  id: number
  externalPositionId: string
  employeeNumber: number
  employeeName: string
  employeeJobTitle: string
  date: Date
  cafeNumber: number
  cafeState: string
  HRJobCode: number
  HRDescription: string
  workstationCode: number
  workstationDescr: string
  IRISJobCode: number
  tips: number
  tipDescription: string
  paid: string
  hours: number
}

export interface TipWorkstationsSearchCriteria {
  employeeName: string
  startDate?: Date
  endDate?: Date
  employeeId: string
  storeNumber?: string
  tipDesc?: string
  pageNumber?: number
}

const defaultTipWorkstationsList: PaginatedList<TipWorkstations> = {
  pageNumber: 0,
  totalPages: 0,
  data: [],
  hasPreviousPage: false,
  hasNextPage: false
}

const getAllTipWorkstations = (): Promise<PaginatedList<TipWorkstations>> => {
  return Axios.get<PaginatedList<TipWorkstations>>(
    `${baseUrl}/TipsWorkstation`,
    { headers: authHelper.authHeader() }
  )
    .then(response => response)
    .then(result => {
      return result.data
    })
    .catch(error => {
      handleResponseAuthorization(error)
      return defaultTipWorkstationsList
    })
}

const combineAvailableSearchParams = (
  searchCriteria: TipWorkstationsSearchCriteria
) => {
  const nameValuePairs = [
    {
      name: 'start',
      value: searchCriteria.startDate ? searchCriteria.startDate.toJSON() : null
    },
    {
      name: 'end',
      value: searchCriteria.endDate ? searchCriteria.endDate.toJSON() : null
    },
    { name: 'employeeNumber', value: searchCriteria.employeeId },
    {
      name: 'employeeName',
      value: searchCriteria.employeeName ? searchCriteria.employeeName : null
    },
    { name: 'pageNumber', value: searchCriteria.pageNumber },
    {
      name: 'storeNumber',
      value:
        searchCriteria.storeNumber && searchCriteria.storeNumber !== 'All'
          ? searchCriteria.storeNumber
          : null
    },
    {
      name: 'tipDescription',
      value: searchCriteria.tipDesc ? searchCriteria.tipDesc : null
    }
  ]
  return nameValuePairs
    .filter(entry => entry.value)
    .map(entry => `${entry.name}=${entry.value}`)
    .join('&')
}

const searchEmpHours = (
  searchCriteria: TipWorkstationsSearchCriteria
): Promise<PaginatedList<TipWorkstations>> => {
  let url = `${baseUrl}/TipsWorkstation/${searchCriteria.employeeId}`

  if (searchCriteria.pageNumber) {
    url = url + '?pageNumber=' + searchCriteria.pageNumber
  }

  const urlParams = combineAvailableSearchParams(searchCriteria)

  return Axios.get<PaginatedList<TipWorkstations>>(
    `${baseUrl}/TipsWorkstation?${urlParams}`,
    { headers: authHelper.authHeader() }
  )
    .then(response => response)
    .then(result => {
      return result.data
    })
    .catch(error => {
      handleResponseAuthorization(error)
      return defaultTipWorkstationsList
    })
}

const exportQuery = async (searchCriteria: TipWorkstationsSearchCriteria) => {
  const urlParams = combineAvailableSearchParams(searchCriteria)

  const url: string = urlParams
    ? `${baseUrl}/TipsWorkstation/ExportSearch?${urlParams}`
    : `${baseUrl}/TipsWorkstation/ExportSearch`

  try {
    const response = await Axios.get(`${url}`, {
      responseType: 'arraybuffer',
      headers: authHelper.authHeader()
    })
    const fileName = response.headers['x-tipr-filename']
    const fileType = response.headers['content-type']
    return { fileName, data: response.data, fileType }
  } catch (error) {
    handleResponseAuthorization(error)
    return Promise.reject(error)
  }
}

export default {
  getAllTipWorkstations,
  searchEmpHours,
  exportQuery
}
