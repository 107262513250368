import React, { useState, FC } from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { Grid, Header, Segment, Form, Button, InputOnChangeData } from 'semantic-ui-react'
import { ApplicationState } from '../store'
import { actionCreators } from '../store/User'
import { TiprUser } from '../services/userService'
import { bindActionCreators, Dispatch } from 'redux'
import { AlertState } from '../store/Alert'
import Alert from './Alert'

interface LoginFormData {
  username: string
  password: string
}

const initialState: LoginFormData = { username: '', password: '' }

/*
interface LoginPageProps {
  user?: UserState
  login: (username: string, password: string) => AppThunkAction<AuthAction>
}
*/

type LoginPageProps = LoginState & typeof actionCreators & RouteComponentProps<{}>

const LoginPage: FC<LoginPageProps> = (props: LoginPageProps) => {
  const [formData, setFormData] = useState<LoginFormData>(initialState)

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>, { name, value }: InputOnChangeData) => {
    setFormData(prevState => ({ ...prevState, [name]: value }))
  }

  const handleLoginClick = async () => {
    if (formData.username && formData.password) {
      props.login(formData.username, formData.password, props.history)
    }
  }

  return (
    <Grid textAlign='center' style={{ height: '70vh' }} verticalAlign='middle'>
      <Grid.Column style={{ maxWidth: 450 }}>
        <Header as='h2' color='blue' textAlign='center'>
          Log In
        </Header>
        <Form size='large'>
          <Segment stacked>
            <Form.Input fluid icon='user' iconPosition='left' placeholder='Username' name='username' value={formData.username} type='text' onChange={handleInputChange} />
            <Form.Input fluid icon='lock' iconPosition='left' placeholder='Password' name='password' value={formData.password} type='password' onChange={handleInputChange} />
            <Button onClick={handleLoginClick} color='blue' fluid size='large'>
              Login
            </Button>
            {props.alert.message
              ? <Alert message={props.alert.message}
                negative={props.alert.type === 'negative'} />
              : null
            }
          </Segment>
        </Form>
      </Grid.Column>
    </Grid>
  )
}

interface LoginState {
  user: TiprUser | undefined
  alert: AlertState
}

const mapState = (state: ApplicationState): (LoginState | undefined) => ({
  user: state.user,
  alert: state.alert
})

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(actionCreators, dispatch)
export default withRouter(connect(mapState, mapDispatchToProps)(LoginPage))
