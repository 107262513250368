import moment from "moment"
import React, { ChangeEvent, SyntheticEvent, useEffect, useState } from "react"
import { Button, Container, Dimmer, Dropdown, DropdownProps, Form, Grid, Icon, Input, InputOnChangeData, Loader, Pagination, PaginationProps, Segment, Table } from "semantic-ui-react"
import orderDetailService, { OrderDetail, OrderDetailSearchCriteria } from '../services/orderDetailService'
import tiprStoreService, { TiprStore } from '../services/tiprStoreService'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import FileSaver from "file-saver"

const OrderDetailDashboard = () => {
    const defaultStore: TiprStore = {
        id: 0,
        companyId: 0,
        paneraCompanyCode: '',
        _3PACompanyCode: '',
        storeNumber: 'All',
        franchiseStoreNumber: '',
        storeName: 'All',
        corporateName: '',
        franchise: '',
        hrCompanyCode: '',
        payrollWeek: '',
        openingDate: null,
        closingDate: null,
        dateCreated: null,
        dateImported: null
    }
    const [orderDetails, setOrderDetails] = useState([] as OrderDetail[])
    const [loaded, setLoaded] = useState(true)
    const [pageNumber, setPageNumber] = useState(1)
    const [totalPages, setTotalPages] = useState(1)
    const [pageLoaded, setPageLoaded] = useState(false)
    const [stores, setStores] = useState<TiprStore[]>([defaultStore])
    const [loadingStores, setLoadingStores] = useState(true)
    const [startDate, setStartDate] = useState<Date | null>(null)
    const [endDate, setEndDate] = useState<Date | null>(null)
    const [orderNumber, setOrderNumber] = useState('')
    const [storeNumber, setStoreNumber] = useState('All')
    const [downloading, setDownloading] = useState(false)
   
    useEffect(() => {
        tiprStoreService
            .getStoresForUser()
            .then(stores => {
                setStores([defaultStore, ...stores])
                setLoadingStores(false)
            })
            .catch(error => console.log('There was an error: ' + error))
    }, [])




    const handlePageChange = (
        event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
        data: PaginationProps
    ) => {
        setPageNumber(Number(data.activePage))
    }

    const handleStartDateChange = (value: Date) =>
        setStartDate(
            moment(value)
                .startOf('day')
                .toDate()
        )
    const handleEndDateChange = (value: Date) =>
        setEndDate(
            moment(value)
                .startOf('day')
                .toDate()
        )
    const handleStoreChange = (
        event: SyntheticEvent<HTMLElement>,
        data: DropdownProps
    ) => setStoreNumber(String(data.value))

    const handlePanOrderIdChange = (
        event: ChangeEvent<HTMLInputElement>,
        { value }: InputOnChangeData
    ) => setOrderNumber(value)


    const gatherSearchCriteria = (): OrderDetailSearchCriteria => {
        const searchCriteria: OrderDetailSearchCriteria = {}

        if (startDate !== null) {
            searchCriteria.startDate = startDate
        }
        if (endDate !== null) {
            searchCriteria.endDate = endDate
        }
        if (orderNumber !== '') {
            searchCriteria.panOrderId = Number(orderNumber)
        }

        if (storeNumber !== '') {
            if (storeNumber.length === 4) {
                searchCriteria.storeNumber = '20' + storeNumber
            } else {
                searchCriteria.storeNumber = storeNumber
            }
        }

        return searchCriteria
    }

    const handleDownloadClick = () => {
        setDownloading(true)
        const searchCriteria = gatherSearchCriteria()
        console.log(orderDetailService)
        orderDetailService.exportQuery(searchCriteria).then(result => {
            if (result) {
                const blob = new Blob([result.data], { type: 'octet/stream' })
                FileSaver.saveAs(blob, result.fileName)
                setDownloading(false)
            }
        })
    }

    const search = () => {
        setLoaded(false)
        const searchCriteria = gatherSearchCriteria()
        searchCriteria.pageNumber = pageNumber

        orderDetailService
            .searchOrderDetails(searchCriteria)
            .then(pagedOrders => {
                setOrderDetails(pagedOrders.data)
                setTotalPages(pagedOrders.totalPages)
            })
            .then(() => setLoaded(true))
            .catch(() => {
                setOrderDetails(orderDetailService.defaultOrderDetailList.data)
                setTotalPages(orderDetailService.defaultOrderDetailList.totalPages)
                //props.error('Error loading data')
            })
    }

    useEffect(() => {
        if (!pageLoaded) {
            setPageLoaded(true)
        } else {
            search()
        }
    }, [pageNumber])

    const handleSearchClick = () => {
        if (pageNumber === 1) {
            search()
        } else {
            setPageNumber(1)
        }
    }


    return (
        <>

            <Grid columns={2}>
                <Grid.Column width='3'>
                    <Grid.Row>
                        <Segment raised size='tiny'>
                            <Form onSubmit={handleSearchClick} size='tiny'>
                                <h2>
                                    <u>Filter &nbsp;&nbsp;&nbsp;</u>
                                </h2>
                                <Form.Field size={'miny'}>
                                    <label>Start Date</label>
                                    <DatePicker
                                        selected={startDate}
                                        onChange={handleStartDateChange}
                                        placeholderText='Start Date'
                                    />
                                </Form.Field>
                                <Form.Field size={'miny'}>
                                    <label>End Date</label>
                                    <DatePicker
                                        selected={endDate}
                                        onChange={handleEndDateChange}
                                        placeholderText='End Date'
                                    />
                                </Form.Field>

                                <Form.Field>
                                    <label>Order Number</label>
                                    <Input
                                        placeholder='Order #'
                                        name='OrderID'
                                        value={orderNumber}
                                        onChange={handlePanOrderIdChange}
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <label>Store</label>
                                    <Dropdown
                                        selection
                                        loading={loadingStores}
                                        options={stores.map(s => ({
                                            text: s.storeNumber,
                                            value: s.storeNumber
                                        }))}
                                        value={storeNumber}
                                        onChange={handleStoreChange}
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <Button loading={!loaded}>Search</Button>
                                </Form.Field>
                            </Form>
                        </Segment>
                    </Grid.Row>
                </Grid.Column>

                {orderDetails.length > 0 ? (
                    <Grid.Column width='12'>
                        <Container>
                            <Button
                                icon
                                loading={downloading}
                                onClick={handleDownloadClick}
                                labelPosition='left'
                                style={{ marginBottom: '12px' }}
                            >
                                <Icon name='download' />
                                Download this data
                            </Button>
                        </Container>
                        <Grid.Row>
                            <Dimmer active={!loaded}>
                                <Loader />
                            </Dimmer>
                            <div className='scrollable'>
                                <Table compact striped singleLine style={{ marginTop: 0 }}>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell>Store Number</Table.HeaderCell>
                                            <Table.HeaderCell>Order Number</Table.HeaderCell>
                                            <Table.HeaderCell>Business Date</Table.HeaderCell>
                                            <Table.HeaderCell>Total Amount</Table.HeaderCell>
                                            <Table.HeaderCell>State</Table.HeaderCell>
                                            <Table.HeaderCell>Tax</Table.HeaderCell>
                                            <Table.HeaderCell>Credit Tips</Table.HeaderCell>
                                            <Table.HeaderCell>Cash Tips</Table.HeaderCell>
                                            <Table.HeaderCell>Net Total</Table.HeaderCell>
                                            <Table.HeaderCell>Sub Total</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {orderDetails.map(
                                            (order): JSX.Element => (
                                                <Table.Row key={order.id}>
                                                    <Table.Cell>{order.storE_NBR}</Table.Cell>
                                                    <Table.Cell>{order.ordeR_NBR}</Table.Cell>
                                                    <Table.Cell>{moment(order.businesS_DT).format('MM/DD/YYYY')}</Table.Cell>
                                                    <Table.Cell>{order.suB_TOTAL}</Table.Cell>
                                                    <Table.Cell> {order.state}</Table.Cell>
                                                    <Table.Cell>{order.tax}</Table.Cell>
                                                    <Table.Cell>{order.crediT_TIPS}</Table.Cell>
                                                    <Table.Cell>{order.casH_TIPS}</Table.Cell>
                                                    <Table.Cell>{order.neT_EXEMPT_TOTAL}</Table.Cell>
                                                    <Table.Cell>{order.suB_TOTAL}</Table.Cell>
                                                </Table.Row>
                                            )
                                        )}
                                    </Table.Body>
                                </Table>
                            </div>
                            <Pagination
                                defaultActivePage={1}
                                totalPages={totalPages}
                                onPageChange={handlePageChange}
                            />
                        </Grid.Row>
                    </Grid.Column>
                ) : (
                    <Grid.Column>
                        <h3> No data to display - see filter options</h3>
                    </Grid.Column>
                )}
            </Grid>
        </>
    )
}
export default OrderDetailDashboard
