import { TiprUser } from '../services/userService'

const authHeader = () => {
  const user: TiprUser = JSON.parse(localStorage.getItem('user') || '')

  if (user && user.token) {
    return { Authorization: 'Bearer ' + user.token }
  } else {
    return {}
  }
}

export default {
  authHeader
}
