import React, { useState, useEffect, SyntheticEvent } from 'react';
import './Dashboard.css';
import '../css/MannaTipBaching.css';
import payWeekService, { PayWeek } from '../services/payWeekService';
import mannaTipBatchingService, { MannaTipBatchingSummary, MannaTipsSearchCriteria, TipBatchingRecord } from '../services/mannaTipBatchingService';
import { Table, Dimmer, Loader, Segment, Form, Button, Grid, Modal, Pagination, PaginationProps, Dropdown, DropdownProps, Icon } from 'semantic-ui-react';
import { withRouter, RouteComponentProps } from 'react-router';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import FileSaver from 'file-saver';
import tiprStoreService, { TiprStore } from '../services/tiprStoreService';

const EmpTipBatching: React.FC<RouteComponentProps> = () => {
  const defaultStore: TiprStore = {
    id: 0, companyId: 0, paneraCompanyCode: '', _3PACompanyCode: '', storeNumber: 'All', franchiseStoreNumber: '', storeName: 'All', corporateName: ' ',
    franchise: '',
    hrCompanyCode: 'All', payrollWeek: '', openingDate: null, closingDate: null, dateCreated: null, dateImported: null
  };

  const [mannaTipBatchingSummary, setTipBatching] = useState<MannaTipBatchingSummary[]>([]);
  const [loaded, setLoaded] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [pageLoaded, setPageLoaded] = useState(false);
  const [hrCompanyCode, setHrCompanyCode] = useState('');
  const [dropdownLoading, setDropdownLoading] = useState(false);
  const [payWeeks, setPayWeeks] = useState<PayWeek[]>([]);
  const [batchRecord, setBatchRecord] = useState<TipBatchingRecord[]>([]);
  const [isBatchDropDownEnabled, setIsBatchDropDownEnabled] = useState(false);
  const [selectedPayWeekStart, setSelectedPayWeekStart] = useState<Date | null>(null);
  const [selectedPayWeekEnd, setSelectedPayWeekEnd] = useState<Date | null>(null);
  const [selectedTipType, setSelectedTipType] = useState('Catering');
  const [stores, setStores] = useState<TiprStore[]>([defaultStore]);
  const [loadingStores, setLoadingStores] = useState(true);
  const dropdownMaxHeight = stores.length * 1000;
  const [batchStatus, setBatchStatus] = useState('');
  const [isBatchExportEnabled, setIsBatchExportEnabled] = useState(false);
  const [isUnBatchEnabled, setIsUnBatchEnabled] = useState(false);
  const [isBatchReExportEnabled, setIsBatchReExportEnabled] = useState(false);
  const [showGrid, setShowGrid] = useState(false);
  const [newBatchID, setNewBatchID] = useState<number | null>(null);
  const [createdBy, setCreatedBy] = useState('');
  const [isDownloading, setIsDownloading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedBatchRecord, setSelectedBatchRecord] = useState<number | null>(null);
  const [showWarning, setShowWarning] = useState(false);
  const [disableSearchButton, setDisableSearchButton] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [unBatchResult, setUnBatchResult] = useState<string | null>(null);
  const [showResultModal, setShowResultModal] = useState(false);
  const [isNotUnbatch, setIsNotUnbatch] = useState(true);

  useEffect(() => {
    const fetchPayWeeks = async () => {
      setDropdownLoading(true);
      const payWeeks = await payWeekService.getPayWeeksForDropdown();
      setPayWeeks(payWeeks);
      setSelectedPayWeekStart(new Date(payWeeks[0].weekStart));
      setDropdownLoading(false);
    };
    fetchPayWeeks();
  }, []);

  useEffect(() => {
    const fetchStores = async () => {
      try {
        const stores = await tiprStoreService.getStoresForUser();
        const uniqueStores = uniqueByKey<TiprStore>([defaultStore, ...stores], 'corporateName');
        setStores(uniqueStores);
        setLoadingStores(false);
      } catch (error) {
        console.log('Error fetching stores:', error);
      }
    };
    fetchStores();
  }, []);

  const uniqueByKey = <T extends { [key: string]: any }>(array: T[], key: string): T[] => {
    return array.reduce((result, item) => {
      return result.some(resultItem => resultItem[key] === item[key]) ? result : [...result, item];
    }, [] as T[]);
  };

  const handleDropdownChange = (e: SyntheticEvent<HTMLElement>, { value }: DropdownProps) => {
    const [start, end] = (value as string).split(',');
    setSelectedPayWeekStart(new Date(start));
    setSelectedPayWeekEnd(new Date(end));
    resetData();
  };

  const handleBatchStatusChange = (event: SyntheticEvent<HTMLElement>, data: DropdownProps) => {
    const value = data.value as string;
    setBatchStatus(value);

    if (value === 'batched' && selectedPayWeekStart) {
      setIsBatchDropDownEnabled(true);
      resetData();
    } else {
      setIsBatchDropDownEnabled(false);
    }
    disableUIForReExportAndBatchingState(value, hrCompanyCode);
    checkForWarning(hrCompanyCode, value);
    resetData();
  };

  const handleBatchRecordChange = (event: SyntheticEvent<HTMLElement>, data: DropdownProps) => {
    const newBatchRecord = Number(data.value);
    if (selectedBatchRecord !== newBatchRecord) {
      resetData();
      setSelectedBatchRecord(newBatchRecord);
      setDisableSearchButton(false);
    } else {
      setSelectedBatchRecord(newBatchRecord);
    }
  };

  const checkForWarning = (hrCode: string, status: string) => {
    if (hrCode === 'All' && status === 'unbatched') {
      setShowWarning(true);
    } else {
      setShowWarning(false);
    }
  };

  const handleStoreChange = (event: SyntheticEvent<HTMLElement>, data: DropdownProps) => {
    const hrCodeValue = String(data.value);
    setHrCompanyCode(hrCodeValue);
    setBatchStatus('');
    checkForWarning(hrCodeValue, batchStatus);
    resetData();
  };

  const updateUIState = (batchStatus: string, hrCode: string, mannaTipBatchingSummary: MannaTipBatchingSummary[]) => {
    const isUnbatched = batchStatus === 'unbatched';
    const isEntitySelected = hrCode !== 'All';
    const hasData = mannaTipBatchingSummary.length > 0;

    setIsBatchExportEnabled(isUnbatched && isEntitySelected && hasData);
  };

  const disableUIForReExportAndBatchingState = (batchStatus: string, hrCode: string) => {
    const isUnbatched = batchStatus === 'unbatched';
    if (isUnbatched) {
      setIsBatchReExportEnabled(false);
      setIsUnBatchEnabled(false);
    }
  };

  const resetData = () => {
    setTipBatching([]);
    setShowGrid(false);
    setBatchRecord([]);
    setSelectedBatchRecord(null);
    setIsBatchExportEnabled(false);
    setIsBatchReExportEnabled(false);
    setIsUnBatchEnabled(false);
    setNewBatchID(null);
    setCreatedBy('');
  };

  const createBatch = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (hrCompanyCode === 'All') {
      alert('You cannot create a batch when entity is set to "All". Please select a specific entity name.');
      resetData();
      return null;
    }
    const searchCriteria = gatherSearchCriteria();
    if (!searchCriteria) return null;

    try {
      const { NewBatchID, createdBy } = await mannaTipBatchingService.createBatch(searchCriteria);
      setNewBatchID(NewBatchID);
      setCreatedBy(createdBy);
      return { NewBatchID, createdBy };
    } catch (error) {
      console.log('Error during batch creation:', error);
      throw error;
    }
  };

  const gatherSearchCriteria = (): MannaTipsSearchCriteria | null => {
    const searchCriteria: MannaTipsSearchCriteria = { hrCompanyCode, selectedTipType, batchStatus, NewBatchID: newBatchID || 0, selectedBatchRecord: selectedBatchRecord || 0 };
    if (selectedPayWeekStart) searchCriteria.selectedPayWeekStart = selectedPayWeekStart;
    if (selectedPayWeekEnd) searchCriteria.selectedPayWeekEnd = selectedPayWeekEnd;
    return searchCriteria;
  };

  const fetchBatchRecords = async (corporate: string, startDate: Date, endDate: Date): Promise<TipBatchingRecord[]> => {
    try {
      const fetchedBatchRecords = await mannaTipBatchingService.getBatchRecord(startDate, endDate, corporate);
      if (fetchedBatchRecords.length === 0) {
        setIsBatchDropDownEnabled(false);
        setDisableSearchButton(true);
        setIsBatchExportEnabled(false);
        if (isNotUnbatch) {
          alert('No batch records available for the selected entity: ['+corporate+'] and pay period.');
        }
      }
      return fetchedBatchRecords;
    } catch (error) {
      console.log('Error fetching batch records:', error);
      return [];
    }
  };

  useEffect(() => {
    if (batchStatus === 'batched' && selectedPayWeekStart && selectedPayWeekEnd) {
      fetchBatchRecords(hrCompanyCode, selectedPayWeekStart, selectedPayWeekEnd).then((fetchedBatchRecords) => {
        setBatchRecord(fetchedBatchRecords);
        setIsBatchDropDownEnabled(fetchedBatchRecords.length > 0);
        updateButtonStates(fetchedBatchRecords, mannaTipBatchingSummary);
      });
    } else {
      setDisableSearchButton(false);
      setIsBatchDropDownEnabled(false);
      updateUIState(batchStatus, hrCompanyCode, mannaTipBatchingSummary);
    }
  }, [batchStatus, hrCompanyCode, selectedPayWeekStart, selectedPayWeekEnd, selectedBatchRecord, mannaTipBatchingSummary]);

  useEffect(() => {
    updateUIState(batchStatus, hrCompanyCode, mannaTipBatchingSummary);
  }, [mannaTipBatchingSummary, batchStatus, hrCompanyCode]);

  const search = () => {
    setIsLoading(true);
    const searchCriteria = gatherSearchCriteria();
    if (!searchCriteria) {
      setIsLoading(false);
      return;
    }
    searchCriteria.pageNumber = pageNumber;
    mannaTipBatchingService.searchEmpTips(searchCriteria)
      .then(pagedOrders => {
        setTipBatching(pagedOrders.data);
        setTotalPages(pagedOrders.totalPages);
        updateButtonStates(batchRecord, pagedOrders.data);
        updateUIState(batchStatus, hrCompanyCode, pagedOrders.data);
      })
      .finally(() => setIsLoading(false));
  };

  const updateButtonStates = (batchRecords: TipBatchingRecord[], searchResults: MannaTipBatchingSummary[]) => {
    const isBatched = batchStatus === 'batched';
    const hasBatchRecord = selectedBatchRecord !== null;
    const hasData = searchResults.length > 0;

    setIsBatchReExportEnabled(isBatched && hasBatchRecord && hasData);
    setIsUnBatchEnabled(isBatched && hasBatchRecord && hasData);
  };

  useEffect(() => {
    if (!pageLoaded) {
      setPageLoaded(true);
    } else {
      search();
    }
  }, [pageNumber]);

  const handlePageChange = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, data: PaginationProps) => {
    setPageNumber(Number(data.activePage));
  };

  const handleSearchClick = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!hrCompanyCode) {
      alert('Please select an Entity.');
      return;
    }
    if (!selectedPayWeekStart) {
      alert('Please select payroll period.');
      return;
    }
    if (!batchStatus) {
      alert('Please select batch status.');
      return;
    }
    if (pageNumber === 1) {
      search();
    } else {
      setPageNumber(1);
    }
  };

  const handleDownloadClick = () => {
    const searchCriteria = gatherSearchCriteria();
    mannaTipBatchingService.exportQuery(searchCriteria!).then(result => {
      if (result) {
        const blob = new Blob([result.data], { type: 'octet/stream' });
        FileSaver.saveAs(blob, result.fileName);
      }
    });
  };

  const handlePayrollExportClick = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsLoading(true);

    try {
      await createBatch(e);
      setShowGrid(true);
    } catch (error) {
      console.error('Error during payroll export:', error);
    } finally {
      setIsLoading(false);
      setIsBatchExportEnabled(false);
    }
  };

  const handleADPFormatDownloadClick = () => {
    setIsLoading(true);
    setIsDownloading(true);
    const searchCriteria = gatherSearchCriteria();
    mannaTipBatchingService.exportADPFormatQuery(searchCriteria!).then(result => {
      if (result) {
        const blob = new Blob([result.data], { type: 'octet/stream' });
        FileSaver.saveAs(blob, result.fileName);
      }
      setIsDownloading(false);
      setIsLoading(false);
    });
  };

  const handleADPFormatReExportClick = () => {
    setIsLoading(true);
    setIsDownloading(true);
    const searchCriteria = gatherSearchCriteria();
    mannaTipBatchingService.ReExportADPFormat(searchCriteria!).then(result => {
      if (result) {
        const blob = new Blob([result.data], { type: 'octet/stream' });
        FileSaver.saveAs(blob, result.fileName);
      }
      setIsDownloading(false);
      setIsLoading(false);
    });
  };

  const handleUnBatchDropDrownStatus = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsNotUnbatch(false);
    setShowModal(true);
  };

  const handleUnBatchConfirm = () => {
    setShowModal(false);
    const searchCriteria = gatherSearchCriteria();
    if (!searchCriteria) {
      setUnBatchResult('Error: Search criteria is null.');
      setShowResultModal(true);
      return;
    }
    mannaTipBatchingService.UnBatch(searchCriteria)
      .then(({ result }) => {
        setUnBatchResult(result);
        setShowResultModal(true);

        if (selectedPayWeekStart && selectedPayWeekEnd) {
          fetchBatchRecords(hrCompanyCode, selectedPayWeekStart, selectedPayWeekEnd).then((fetchedBatchRecords) => {
            setBatchRecord(fetchedBatchRecords);
            setIsBatchDropDownEnabled(fetchedBatchRecords.length > 0);
            updateButtonStates(fetchedBatchRecords, mannaTipBatchingSummary);
            setIsNotUnbatch(true);
          });
        }
      })
      .catch((error) => {
        setUnBatchResult(`Error: ${error.message}`);
        setShowResultModal(true);
        setIsNotUnbatch(true);
      });
  };

  const handleUnBatchCancel = () => {
    setShowModal(false);
  };

  const handleResultModalClose = () => {
    setShowResultModal(false);
    resetData();
  };

  const getResultTextColor = (result: string): string => {
    if (result.includes('Success:')) {
      return 'green';
    } else if (result.includes('Fail:')) {
      return 'red';
    } else if (result.includes('Error:')) {
      return 'orange';
    } else {
      return 'black';
    }
  };

  return (
    <>
      <Dimmer active={isLoading} page>
        <Loader>Loading...</Loader>
      </Dimmer>
      <Grid columns={2}>
        <Grid.Column width='4' textAlign='left'>
          <Grid.Row>
            <Segment raised size='tiny'>
              <Form onSubmit={handleSearchClick} size='tiny'>
                <h2> <u>Filter </u> </h2>
                <Form.Field>
                  <label>Entity</label>
                  <Dropdown
                    id="uniqueDropdown"
                    style={{ maxHeight: `${dropdownMaxHeight}px` }}
                    selection
                    loading={loadingStores}
                    options={stores.map(s => ({
                      text: `${s.hrCompanyCode} - ${s.corporateName}`,
                      value: s.hrCompanyCode
                    }))}
                    name='StoreNum'
                    value={hrCompanyCode}
                    onChange={handleStoreChange}
                  />
                  {showWarning && <span className="warning">Cannot create a batch with 'All' entity. Please select a specific entity.</span>}
                </Form.Field>
                <Form.Field>
                  <label>Payroll Period</label>
                  <Dropdown
                    className="my-dropdown"
                    placeholder='Select pay period'
                    fluid
                    selection
                    loading={dropdownLoading}
                    options={payWeeks.map(payWeek => ({
                      key: payWeek.weekStart,
                      text: `${payWeek.weekStart} - ${payWeek.weekEnd}`,
                      value: `${payWeek.weekStart},${payWeek.weekEnd}`
                    }))}
                    onChange={handleDropdownChange}
                  />
                </Form.Field>
                <Form.Field>
                  <label>Batch Status</label>
                  <Dropdown
                    placeholder='Select Batch Status'
                    fluid
                    selection
                    value={batchStatus || ''}
                    options={[
                      { key: 'all', text: 'All', value: 'all' },
                      { key: 'batched', text: 'Batched', value: 'batched' },
                      { key: 'unbatched', text: 'Unbatched', value: 'unbatched' }
                    ]}
                    onChange={handleBatchStatusChange}
                  />
                </Form.Field>
                {isBatchDropDownEnabled && (
                  <Form.Field>
                    <label>Select Batch Record</label>
                    <Dropdown
                      className="batchingRecord-dropdown"
                      placeholder='Select batch record'
                      fluid
                      selection
                      loading={dropdownLoading}
                      onChange={handleBatchRecordChange}
                      options={batchRecord.map(br => {
                        const weekStartDate = new Date(br.weekStart).toLocaleDateString();
                        const weekEndDate = new Date(br.weekEnd).toLocaleDateString();
                        return {
                          key: br.batchID,
                          text: `Batch_${br.batchID},${br.companyEntity},${weekStartDate} to ${weekEndDate}`,
                          value: `${br.batchID}`
                        };
                      })}
                    />
                  </Form.Field>
                )}
                <Form.Field>
                  <div className="flex-container">
                    <Button
                      className="uniform-button"
                      type="Submit"
                      disabled={disableSearchButton}
                    > Search
                    </Button>
                    <Button
                      className="uniform-button"
                      type="button"
                      disabled={!isBatchExportEnabled}
                      onClick={handlePayrollExportClick}
                    > Create Batch
                    </Button>
                    <Button
                      className="uniform-button"
                      type="Submit"
                      disabled={!isBatchReExportEnabled}
                      onClick={handleADPFormatReExportClick}
                    >Re-export Batch
                    </Button>
                    <Button
                      style={{ background: '#FA8072' }}
                      className="uniform-button"
                      type="Submit"
                      disabled={!isUnBatchEnabled}
                      onClick={handleUnBatchDropDrownStatus}
                    > Unbatch
                    </Button>
                  </div>
                </Form.Field>
              </Form>
            </Segment>
          </Grid.Row>
          <br />
          {hrCompanyCode !== 'All' && (
            <Grid.Row style={{ display: showGrid ? 'block' : 'none' }}>
              <Segment raised size='tiny'>
                {showGrid && (
                  <div className="show-grid-container">
                    <div style={{ fontWeight: 'bold', color: 'black' }}>Entity</div>
                    <div></div>
                    <div>{hrCompanyCode}</div><br />
                    <div></div><br />
                    <div style={{ fontWeight: 'bold' }}>New BatchID</div>
                    <div></div>
                    <div>{newBatchID}</div><br />
                    <div></div><br />
                    <div style={{ fontWeight: 'bold' }}>Created</div>
                    <div></div>
                    <div>{createdBy}</div><br />
                    <div></div><br />
                    <Button
                      icon
                      onClick={handleADPFormatDownloadClick}
                      style={{ marginTop: '5px', borderColor: '#4CAF50', color: 'white', borderWidth: '2px', borderStyle: 'solid', cursor: 'pointer', backgroundColor: '#2185d0' }}
                      type="Download"
                      loading={isDownloading}
                      disabled={isBatchDropDownEnabled}
                    >
                      <Icon name='download' />
                      Export
                    </Button>
                  </div>
                )}
              </Segment>
            </Grid.Row>
          )}
        </Grid.Column>
        {mannaTipBatchingSummary.length > 0 ? (
          <Grid.Column >
            <Grid.Row>
              <Button icon onClick={handleDownloadClick} labelPosition='left' style={{ marginBottom: '12px' }}> <Icon name='download' />  Download this data</Button>
            </Grid.Row>
            <Grid.Row>
              <Segment floated='left' size='small'>
                <Dimmer active={!loaded}>
                  <Loader />
                </Dimmer>
                <Table singleLine striped selectable unstackable style={{ marginTop: 0, backgroundColor: '#f7f7f7', fontSize: '12px' }} size='small'>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell style={{ backgroundColor: 'black', color: 'white' }}>Employee Id</Table.HeaderCell>
                      <Table.HeaderCell style={{ backgroundColor: 'black', color: 'white' }}>ADP Id</Table.HeaderCell>
                      <Table.HeaderCell style={{ backgroundColor: 'black', color: 'white' }}>Batch ID </Table.HeaderCell>
                      <Table.HeaderCell style={{ backgroundColor: 'black', color: 'white' }}>Employee Name</Table.HeaderCell>
                      <Table.HeaderCell style={{ backgroundColor: 'black', color: 'white' }}>Job Title</Table.HeaderCell>
                      <Table.HeaderCell style={{ backgroundColor: 'black', color: 'white' }}>Date</Table.HeaderCell>
                      <Table.HeaderCell style={{ backgroundColor: 'black', color: 'white' }}>Store</Table.HeaderCell>
                      <Table.HeaderCell style={{ backgroundColor: 'black', color: 'white' }}>Tip</Table.HeaderCell>
                      <Table.HeaderCell style={{ backgroundColor: 'black', color: 'white' }}>Tip Description</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {mannaTipBatchingSummary.map(workstation => (
                      <Table.Row key={workstation.id}>
                        <Table.Cell>{workstation.employeeNumber}</Table.Cell>
                        <Table.Cell>{workstation.externalPositionId}</Table.Cell>
                        <Table.Cell>{workstation.batchID}</Table.Cell>
                        <Table.Cell>{workstation.employeeName}</Table.Cell>
                        <Table.Cell>{workstation.employeeJobTitle}</Table.Cell>
                        <Table.Cell>{moment(workstation.date).format('MM/DD/YYYY')}</Table.Cell>
                        <Table.Cell>{workstation.cafeNumber}</Table.Cell>
                        <Table.Cell>{workstation.tips}</Table.Cell>
                        <Table.Cell>{workstation.tipDescription}</Table.Cell>
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>
                <Pagination defaultActivePage={1} totalPages={totalPages} onPageChange={handlePageChange} />
              </Segment>
            </Grid.Row>
          </Grid.Column>
        ) : (
          <Grid.Column>
            <h3> No data to display - see filter options</h3>
          </Grid.Column>
        )}
      </Grid>
      <Modal open={showModal} onClose={handleUnBatchCancel} size='small' style={{ backgroundColor: 'white', borderColor: '#337ab7' }}>
        <Modal.Header style={{ backgroundColor: '#337ab7', color: 'white' }}>Confirm Unbatch</Modal.Header>
        <Modal.Content style={{ backgroundColor: 'white' }}>
          <p>Are you sure you want to unbatch this batch?</p>
        </Modal.Content>
        <Modal.Actions style={{ backgroundColor: 'white' }}>
          <Button onClick={handleUnBatchCancel} style={{ backgroundColor: 'white', color: '#337ab7', border: '1px solid #337ab7' }}>No</Button>
          <Button positive onClick={handleUnBatchConfirm} style={{ backgroundColor: '#337ab7', color: 'white' }}>Yes</Button>
        </Modal.Actions>
      </Modal>

      <Modal open={showResultModal} onClose={handleResultModalClose} size='small' style={{ backgroundColor: 'white', borderColor: '#337ab7' }}>
        <Modal.Header style={{ backgroundColor: '#337ab7', color: 'white' }}>UnBatch Result</Modal.Header>
        <Modal.Content style={{ backgroundColor: 'white' }}>
          <p style={{ color: getResultTextColor(unBatchResult || '') }}>{unBatchResult}</p>
        </Modal.Content>
        <Modal.Actions style={{ backgroundColor: 'white' }}>
          <Button positive onClick={handleResultModalClose} style={{ backgroundColor: '#337ab7', color: 'white' }}>OK</Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default withRouter(EmpTipBatching);
