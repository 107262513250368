import React, { useState, useEffect } from 'react'
import { Grid, Form, Dropdown, DropdownProps } from 'semantic-ui-react'
import './Dashboard.css'
import 'react-datepicker/dist/react-datepicker.css'
import reportService from '../services/reportService'
import FileSaver from 'file-saver'
import accountingPeriodService, { AccountingPeriod } from '../services/accountingPeriodService'
import moment from 'moment'

// This page needs authorization for TRG users only if we make TIPR available to clients

const TrgTipReport = () => {
    const [loading, setLoading] = useState(false)

    const [accountingPeriods, setAccountingPeriods] = useState([] as AccountingPeriod[])
    const [selectedValue, setSelectedValue] = useState<number | undefined>(undefined)

    useEffect(() => {
        accountingPeriodService.getLatestAccountingPeriods()
            .then(data => { console.log(data); return data })
            .then(data => setAccountingPeriods(data))
            .catch(error => console.log(error))
    }, [])

    const options = accountingPeriods.map(pp => (
        { key: pp.recid, text: `${moment(pp.accountingPeriodStart).format('MM/DD/YYYY')} - ${moment(pp.accountingPeriodEnd).format('MM/DD/YYYY')}`, value: pp.recid }
    ))

    const handleAccountingPeriodChange = (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
        setSelectedValue(Number(data.value))
    }

    const runReport = () => {
        setLoading(true)
        const selected = accountingPeriods.find(pp => pp.recid === selectedValue)
        if (!selected) { return }
        // start/end date did not have toISOString for some reason, did not behave as Dates
        reportService.trgTipReport(moment(selected.accountingPeriodStart).toDate(), moment(selected.accountingPeriodEnd).toDate())
            .then(result => {
                if (result) {
                    const blob = new Blob([result.data], { type: 'octet/stream' })
                    FileSaver.saveAs(blob, result.fileName)
                } else {
                    console.error('There was an error while downloading the report.')
                    setLoading(false)
                }
            })
            .then(() => {
                setLoading(false)
            })
    }

    return (
        <Grid columns={2} padded>
            <Grid.Column>
                <Grid.Row>
                    <Form widths="equal">
                        <Form.Field>
                            <label>Accounting Period</label>
                            <Dropdown onChange={handleAccountingPeriodChange}
                                options={options}
                                placeholder='Accounting Period'
                                selection
                                value={selectedValue}
                            />
                        </Form.Field>
                        <Form.Button loading={loading} onClick={runReport}>Submit</Form.Button>
                    </Form>
                </Grid.Row>
            </Grid.Column>
        </Grid>
    )
}

export default TrgTipReport
