import Axios, { AxiosResponse } from 'axios'
import config from '../utils/config'
import authHelper from '../utils/authHelper'
import handleResponseAuthorization from '../utils/responseHelper'
const baseUrl = config.API_BASE_URL
export interface PaginatedList<T> {
	pageNumber: number
	totalPages: number
	data: T[]
	hasPreviousPage: boolean
	hasNextPage: boolean
}
export interface MannaTipBatchingSummary {
	CoCode: string
	FileNumber: string
	TempDepartment: string
	RegularHours: string
	OvertimeHours: string
	RateCode: string
	Earnings3Code: string
	Earnings3Amount: string
	id: number
	externalPositionId: string
	employeeNumber: number
	employeeName: string
	employeeJobTitle: string
	date: Date
	cafeNumber: number
	cafeState: string
	HRJobCode: number
	HRDescription: string
	workstationCode: number
	workstationDescr: string
	IRISJobCode: number
	tips: number
	tipDescription: string
	paid: string
	hours: number
	batchID: number
	createdBy: string
}
export interface TipBatchingRecord {
	batchID: number
	CompanyId: number
	companyEntity: string
	TipType: string
	weekStart: Date
	weekEnd: Date
	UnbatchedBy: string
	UnbatchedOn: Date
	RecordTotals: number
	Locked: Boolean
	CreatedBy: string
	created: Date
}
export interface MannaTipsSearchCriteria {
	selectedPayWeekStart?: Date
	selectedPayWeekEnd?: Date
	selectedTipType: string
	batchStatus: string
	hrCompanyCode: string
	pageNumber?: number
	NewBatchID: number
	selectedBatchRecord?: number
}
const defaultTipsAllocationSummaryList: PaginatedList<MannaTipBatchingSummary> = {
	pageNumber: 0,
	totalPages: 0,
	data: [],
	hasPreviousPage: false,
	hasNextPage: false
}

const getAllTipBatchingSummary = (): Promise<PaginatedList<MannaTipBatchingSummary>> => {
	return Axios.get<PaginatedList<MannaTipBatchingSummary>>(
		`${baseUrl}/MannaTipBatching`,
		{ headers: authHelper.authHeader() }
	)
		.then(response => response)
		.then(result => {
			return result.data
		})
		.catch(error => {
			handleResponseAuthorization(error)
			return defaultTipsAllocationSummaryList
		})
}
const formatDate = (date: Date): string => {
	const year = date.getUTCFullYear();
	const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // months are 0-indexed
	const day = String(date.getUTCDate()).padStart(2, '0');
	return `${year}-${month}-${day}`;
};

const createBatch = (batchData: MannaTipsSearchCriteria): Promise<{ NewBatchID: number, createdBy: string }> => {
	console.log('batchData:', batchData); // Log the batchData to see its value
	// Format dates before using them in the URL
	const formattedStart = batchData.selectedPayWeekStart ? formatDate(batchData.selectedPayWeekStart) : "";
	const formattedEnd = batchData.selectedPayWeekEnd ? formatDate(batchData.selectedPayWeekEnd) : "";
	// Construct the URL with query parameters
	const url = `${baseUrl}/MannaTipBatching/CreateBatch?` +
		`start=${formattedStart}&` +
		`end=${formattedEnd}&` +
		`tipType=${batchData.selectedTipType}&` +
		`corporateName=${batchData.hrCompanyCode}&` +
		`batchStatus=${batchData.batchStatus}`;
	return Axios.post<MannaTipBatchingSummary>(
		url,
		null, // No body data since everything is in the URL now
		{ headers: authHelper.authHeader() }
	)
		.then(response => {
			console.log("Response Data:", response.data);  // This will show the entire response data
			console.log("batchID:", response.data.batchID);
			console.log("createdBy:", response.data.createdBy);
			return {
				NewBatchID: response.data.batchID,
				createdBy: response.data.createdBy
			};
		})
		.catch(error => {
			handleResponseAuthorization(error);
			throw error;
		});
};

const getBatchRecord = (startWeek: Date, endWeek: Date, corporateName: string): Promise<TipBatchingRecord[]> => {
	// Format the dates and construct the URL with query parameters
	const formattedStartWeek = formatDate(startWeek);
	const formattedEndWeek = formatDate(endWeek);

	const url = `${baseUrl}/MannaTipBatching/GetBatchRecord?startWeek=${formattedStartWeek}&endWeek=${formattedEndWeek}&corporateName=${corporateName}`;
	return Axios.get<TipBatchingRecord[]>(url, { headers: authHelper.authHeader() })
		.then((response: AxiosResponse<TipBatchingRecord[]>) => {
			if (!response.data) {
				throw new Error('No tip record data received.')
			}
			return response.data;
		})
		.catch(error => {
			console.log(error);
			handleResponseAuthorization(error);
			throw error;
		});
}
const combineAvailableSearchParams = (
	searchCriteria: MannaTipsSearchCriteria
) => {
	const nameValuePairs = [
		{
			name: 'start',
			value: searchCriteria.selectedPayWeekStart ? searchCriteria.selectedPayWeekStart.toJSON() : null
		},
		{
			name: 'end',
			value: searchCriteria.selectedPayWeekEnd ? searchCriteria.selectedPayWeekEnd.toJSON() : null
		},
		{
			name: 'tipType',
			value: (searchCriteria.selectedTipType && searchCriteria.selectedTipType !== '') ? searchCriteria.selectedTipType : null
		},
		{
			name: 'corporateName',
			value: searchCriteria.hrCompanyCode && searchCriteria.hrCompanyCode !== 'All'
				? searchCriteria.hrCompanyCode
				: null
		},
		{
			name: 'batchStatus',
			value: searchCriteria.batchStatus && searchCriteria.batchStatus !== 'All'
				? searchCriteria.batchStatus
				: null
		},
		{
			name: 'batchID',
			value: searchCriteria.selectedBatchRecord
		},		
		{
			name: 'NewBatchID',
			value: searchCriteria.NewBatchID
		},
		{ name: 'pageNumber', value: searchCriteria.pageNumber }
	]
	return nameValuePairs
		.filter(entry => entry.value)
		.map(entry => `${entry.name}=${entry.value}`)
		.join('&')
}
const searchEmpTips = (
	searchCriteria: MannaTipsSearchCriteria
): Promise<PaginatedList<MannaTipBatchingSummary>> => {
	let url = `${baseUrl}/MannaTipBatching/${searchCriteria.hrCompanyCode}`
	if (searchCriteria.pageNumber) {
		url = url + '?pageNumber=' + searchCriteria.pageNumber
	}
	const urlParams = combineAvailableSearchParams(searchCriteria)
	return Axios.get<PaginatedList<MannaTipBatchingSummary>>(
		`${baseUrl}/MannaTipBatching?${urlParams}`,
		{ headers: authHelper.authHeader() }
	)
		.then(response => response)
		.then(result => {
			return result.data
		})
		.catch(error => {
			handleResponseAuthorization(error)
			return defaultTipsAllocationSummaryList
		})
}
const exportQuery = async (searchCriteria: MannaTipsSearchCriteria) => {
	const urlParams = combineAvailableSearchParams(searchCriteria)
	const url: string = urlParams
		? `${baseUrl}/MannaTipBatching/ExportSearch?${urlParams}`
		: `${baseUrl}/MannaTipBatching/ExportSearch`
	try {
		const response = await Axios.get(`${url}`, {
			responseType: 'arraybuffer',
			headers: authHelper.authHeader()
		})
		const fileName = response.headers['x-tipr-filename']
		const fileType = response.headers['content-type']
		return { fileName, data: response.data, fileType }
	} catch (error) {
		handleResponseAuthorization(error)
		return Promise.reject(error)
	}
}
const ReExportADPFormat = async (searchCriteria: MannaTipsSearchCriteria) => {
	const urlParams = combineAvailableSearchParams(searchCriteria)
	const url: string = urlParams
		? `${baseUrl}/MannaTipBatching/ReExportADPFormat?${urlParams}`
		: `${baseUrl}/MannaTipBatching/ReExportADPFormat`
	try {
		const response = await Axios.get(`${url}`, {
			responseType: 'arraybuffer',
			headers: authHelper.authHeader()
		})
		const fileName = response.headers['x-tipr-filename']
		const fileType = response.headers['content-type']
		return { fileName, data: response.data, fileType }
	} catch (error) {
		handleResponseAuthorization(error)
		return Promise.reject(error)
	}
}
const exportADPFormatQuery = async (searchCriteria: MannaTipsSearchCriteria) => {
	const urlParams = combineAvailableSearchParams(searchCriteria)
	const url: string = urlParams
		? `${baseUrl}/MannaTipBatching/ExportADPFormatSearch?${urlParams}`
		: `${baseUrl}/MannaTipBatching/ExportADPFormatSearch`
	try {
		const response = await Axios.get(`${url}`, {
			responseType: 'arraybuffer',
			headers: authHelper.authHeader()
		})
		const fileName = response.headers['x-tipr-filename']
		const fileType = response.headers['content-type']
		return { fileName, data: response.data, fileType }
	} catch (error) {
		handleResponseAuthorization(error)
		return Promise.reject(error)
	}
}


const UnBatch = (batchData: MannaTipsSearchCriteria): Promise<{ result: string }> => {
	const formattedEnd = batchData.selectedPayWeekEnd ? formatDate(batchData.selectedPayWeekEnd) : "";

	// Validate batchId
	if (!batchData.selectedBatchRecord || isNaN(batchData.selectedBatchRecord)) {
		return Promise.reject(new Error("Invalid batch ID."));
	}

	// Construct the URL with query parameters, ensuring they are properly encoded
	const url = `${baseUrl}/MannaTipBatching/UnBatch?` +
		`endWeek=${encodeURIComponent(formattedEnd)}&` +
		`batchId=${encodeURIComponent(batchData.selectedBatchRecord)}`;

	// Make the GET request using Axios
	return Axios.get<{ result: string }>(url, { headers: authHelper.authHeader() })
		.then(response => {
			console.log("Response Data:", response.data);
			return {
				result: response.data.result
			};
		})
		.catch(error => {
			handleResponseAuthorization(error);
			throw error;
		});
};


export default {
	getAllTipBatchingSummary,
	searchEmpTips,
	exportQuery,
	exportADPFormatQuery,
	ReExportADPFormat,
	UnBatch,
	createBatch,
	getBatchRecord
}
