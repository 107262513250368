import Axios from 'axios'
import config from '../utils/config'

const baseUrl = config.API_BASE_URL

export interface TiprUser {
  name: string
  username: string
  company: string
  companyId: number
  token: string
  firstName: string
  lastName: string
}

export const login = (username: string, password: string) => {
  return Axios.post<TiprUser>(`${baseUrl}/authenticate`, { username, password })
    .then(response => response.data)
    .then(user => {
      localStorage.setItem('user', JSON.stringify(user))
      return user
    })
}

export const logout = () => {
  localStorage.removeItem('user')
}
//SMS The current code exports an object by default, but it also separately exports the functions for login and logout to ensure that the user is logged out if they copy and paste the page URL. 
//Although it may not be necessary, the code is currently kept as is to avoid potential issues.
export default {
  login,
  logout
}
