import Axios from 'axios'
import config from '../utils/config'
import authHelper from '../utils/authHelper'
import handleResponseAuthorization from '../utils/responseHelper'

const baseUrl = config.API_BASE_URL

export interface PaginatedList<T> {
  pageNumber: number
  totalPages: number
  data: T[]
  hasPreviousPage: boolean
  hasNextPage: boolean
}

export interface tipsAllocationSummary {
  id: number
  employeeName: string
  date: Date
  cafeNumber: number
  sunday: number
  monday: number
  tuesday: number
  wednesday: number
  thursday: number
  friday: number
  saturday: number
  weekTotal: number
}

export interface TipsAllocationSummarySearchCriteria {
  startDate?: Date
  endDate?: Date
  storeNumber?: string
  pageNumber?: number
}

const defaultTipsAllocationSummaryList: PaginatedList<tipsAllocationSummary> = {
  pageNumber: 0,
  totalPages: 0,
  data: [],
  hasPreviousPage: false,
  hasNextPage: false
}

const getAllTipsAllocationSummary = (): Promise<PaginatedList<tipsAllocationSummary>> => {
  return Axios.get<PaginatedList<tipsAllocationSummary>>(
    `${baseUrl}/tipsAllocationSummary`,
    { headers: authHelper.authHeader() }
  )
    .then(response => response)
    .then(result => {
      return result.data
    })
    .catch(error => {
      handleResponseAuthorization(error)
      return defaultTipsAllocationSummaryList
    })
}

const combineAvailableSearchParams = (
  searchCriteria: TipsAllocationSummarySearchCriteria
) => {
  const nameValuePairs = [
    {
      name: 'start',
      value: searchCriteria.startDate ? searchCriteria.startDate.toJSON() : null
    },
    {
      name: 'end',
      value: searchCriteria.endDate ? searchCriteria.endDate.toJSON() : null
    },    
    { name: 'pageNumber', value: searchCriteria.pageNumber },
    {
      name: 'storeNumber',
      value:
        searchCriteria.storeNumber && searchCriteria.storeNumber !== 'All'
          ? searchCriteria.storeNumber
          : null
    }
  ]
  return nameValuePairs
    .filter(entry => entry.value)
    .map(entry => `${entry.name}=${entry.value}`)
    .join('&')
}

const searchEmpHours = (
  searchCriteria: TipsAllocationSummarySearchCriteria
): Promise<PaginatedList<tipsAllocationSummary>> => {
  let url = `${baseUrl}/tipsAllocationSummary/${searchCriteria.storeNumber}`

  if (searchCriteria.pageNumber) {
    url = url + '?pageNumber=' + searchCriteria.pageNumber
  }

  const urlParams = combineAvailableSearchParams(searchCriteria)

  return Axios.get<PaginatedList<tipsAllocationSummary>>(
    `${baseUrl}/tipsAllocationSummary?${urlParams}`,
    { headers: authHelper.authHeader() }
  )
    .then(response => response)
    .then(result => {
      return result.data
    })
    .catch(error => {
      handleResponseAuthorization(error)
      return defaultTipsAllocationSummaryList
    })
}

const exportQuery = async (searchCriteria: TipsAllocationSummarySearchCriteria) => {
  const urlParams = combineAvailableSearchParams(searchCriteria)

  const url: string = urlParams
    ? `${baseUrl}/tipsAllocationSummary/ExportSearch?${urlParams}`
    : `${baseUrl}/tipsAllocationSummary/ExportSearch`

  try {
    const response = await Axios.get(`${url}`, {
      responseType: 'arraybuffer',
      headers: authHelper.authHeader()
    })
    const fileName = response.headers['x-tipr-filename']
    const fileType = response.headers['content-type']
    return { fileName, data: response.data, fileType }
  } catch (error) {
    handleResponseAuthorization(error)
    return Promise.reject(error)
  }
}

export default {
  getAllTipsAllocationSummary,
  searchEmpHours,
  exportQuery
}
