import ReactGA from 'react-ga'

ReactGA.initialize('UA-151041457-1')

const pageView = (page: string) => {
  ReactGA.pageview(page)
}

export default {
  pageView
}
