import React, { useState, useEffect, ChangeEvent, SyntheticEvent } from 'react'
import './Dashboard.css'
import tipWorkstationsService, {
    TipWorkstations,
    TipWorkstationsSearchCriteria
} from '../services/tipWorkstationsService'
import {
    Table,
    Dimmer,
    Loader,
    Segment,
    Form,
    Input,
    Button,
    Grid,
    InputOnChangeData,
    Pagination,
    PaginationProps,
    Dropdown,
    DropdownProps,
    Icon
} from 'semantic-ui-react'
import { withRouter, RouteComponentProps } from 'react-router'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import moment from 'moment'
import FileSaver from 'file-saver'
import tiprStoreService, { TiprStore } from '../services/tiprStoreService'

const EmpHoursDashboard: React.FC<RouteComponentProps> = () => {
    const tipDescOptions = [
        {
            text: 'All',
            value: 'All'
        },
        {
            text: 'Cafe/Team Tips',
            value: 'CAFE TIPS'
        },
        {
            text: 'Catering Tips',
            value: 'CATERING TIPS'
        },
        {
            text: 'Delivery Tips',
            value: 'DELIVERY TIPS'
        },
        {
            text: 'Hours',
            value: 'HOURS'
        }
    ]

    const defaultStore: TiprStore = {
        id: 0,
        companyId: 0,
        paneraCompanyCode: '',
        _3PACompanyCode: '',
        storeNumber: 'All',
        franchiseStoreNumber: '',
        storeName: 'All',
        corporateName: '',
        franchise: '',
        hrCompanyCode: '',
        payrollWeek: '',
        openingDate: null,
        closingDate: null,
        dateCreated: null,
        dateImported: null
    }

    const [tipWorkstations, setTipWorkstations] = useState(
        [] as TipWorkstations[]
    )
    const [loaded, setLoaded] = useState(true)
    const [pageNumber, setPageNumber] = useState(1)
    const [totalPages, setTotalPages] = useState(1)
    const [pageLoaded, setPageLoaded] = useState(false)

    // Search parameters
    const [employeeId, setEmployeeId] = useState('')
    const [employeeName, setEmployeeName] = useState('')
    const [storeNumber, setStoreNumber] = useState('')
    const [startDate, setStartDate] = useState<Date | null>(null)
    const [endDate, setEndDate] = useState<Date | null>(null)
    const [tipDesc, setTipDesc] = useState(tipDescOptions[0].value)
    const [stores, setStores] = useState<TiprStore[]>([defaultStore])
    const [loadingStores, setLoadingStores] = useState(true)

    useEffect(() => {
        tiprStoreService
            .getStoresForUser()
            .then(stores => {
                setStores([defaultStore, ...stores])
                setLoadingStores(false)
            })
            .catch(error => console.log('There was an error: ' + error))
    }, [])

    const handleStartDateChange = (value: Date) =>
        setStartDate(
            moment(value)
                .startOf('day')
                .toDate()
        )
    const handleEndDateChange = (value: Date) =>
        setEndDate(
            moment(value)
                .startOf('day')
                .toDate()
        )
    const handleEmployeeIdChange = (
        event: ChangeEvent<HTMLInputElement>,
        { value }: InputOnChangeData
    ) => setEmployeeId(value)

    const handleEmployeeNameChange = (
        event: ChangeEvent<HTMLInputElement>,
        { value }: InputOnChangeData
    ) => setEmployeeName(value)

    const handleStoreChange = (
        event: SyntheticEvent<HTMLElement>,
        data: DropdownProps
    ) => setStoreNumber(String(data.value))

    const handleTipDescChange = (
        event: SyntheticEvent<HTMLElement>,
        data: DropdownProps
    ) => setTipDesc(String(data.value))

    const gatherSearchCriteria = (): TipWorkstationsSearchCriteria | null => {
        const searchCriteria: TipWorkstationsSearchCriteria = { employeeId, employeeName }

        // Required to have emp Id
        if (employeeId === undefined) {
            return null
        }
        searchCriteria.employeeId = employeeId

        if (employeeName !== null) {
            searchCriteria.employeeName = employeeName
        }


        if (startDate !== null) {
            searchCriteria.startDate = startDate
        }

        if (endDate !== null) {
            searchCriteria.endDate = endDate
        }

        if (tipDesc !== 'All') {
            searchCriteria.tipDesc = tipDesc
        }

        if (storeNumber !== '') {
            if (storeNumber.length === 4) {
                searchCriteria.storeNumber = '20' + storeNumber
            } else {
                searchCriteria.storeNumber = storeNumber
            }
        }

        return searchCriteria
    }

    const search = () => {
        setLoaded(false)
        const searchCriteria = gatherSearchCriteria()
        if (searchCriteria == null) {
            // set error message
            return
        }
        searchCriteria.pageNumber = pageNumber
        tipWorkstationsService
            .searchEmpHours(searchCriteria)
            .then(pagedOrders => {
                setTipWorkstations(pagedOrders.data)
                setTotalPages(pagedOrders.totalPages)
            })
            .then(() => setLoaded(true))
    }

    useEffect(() => {
        if (!pageLoaded) {
            setPageLoaded(true)
        } else {
            search()
        }
    }, [pageNumber])

    const handlePageChange = (
        event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
        data: PaginationProps
    ) => {
        setPageNumber(Number(data.activePage))
    }

    const handleSearchClick = () => {
        if (pageNumber === 1) {
            search()
        } else {
            setPageNumber(1)
        }
    }

    const handleDownloadClick = () => {
        const searchCriteria = gatherSearchCriteria()
        tipWorkstationsService.exportQuery(searchCriteria!).then(result => {
            if (result) {
                const blob = new Blob([result.data], { type: 'octet/stream' })
                FileSaver.saveAs(blob, result.fileName)
            }
        })
    }

    return (
        <>
            <Grid columns={2}>
                <Grid.Column width='3'>
                    <Grid.Row>
                        <Segment raised size='tiny'>
                            <Form onSubmit={handleSearchClick} size='tiny'>
                                <h2>
                                    <u>Filter &nbsp;&nbsp;&nbsp;</u>
                                </h2>
                                <Form.Field>
                                    <label id='eId'>Employee Id</label>
                                    <Input
                                        placeholder='Employee ID'
                                        name='EmployeeId'
                                        value={employeeId}
                                        onChange={handleEmployeeIdChange}
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <label id='employeeName'>Employee Name</label>
                                    <Input
                                        placeholder='Employee Name'
                                        name='employeeName'
                                        value={employeeName}
                                        onChange={handleEmployeeNameChange}
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <label>Store Number</label>
                                    <Dropdown
                                        selection
                                        loading={loadingStores}
                                        options={stores.map(s => ({
                                            text: s.storeNumber,
                                            value: s.storeNumber
                                        }))}
                                        name='StoreNum'
                                        value={storeNumber}
                                        onChange={handleStoreChange}
                                    />
                                </Form.Field>
                                <Form.Field size={'miny'}>
                                    <label>Start Date</label>
                                    <DatePicker
                                        selected={startDate}
                                        onChange={handleStartDateChange}
                                        placeholderText='Start Date'
                                    />
                                </Form.Field>
                                <Form.Field size={'miny'}>
                                    <label>End Date</label>
                                    <DatePicker
                                        selected={endDate}
                                        onChange={handleEndDateChange}
                                        placeholderText='End Date'
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <label>Tip Description</label>
                                    <Dropdown
                                        style={{ minWidth: '12px' }}
                                        selection
                                        options={tipDescOptions}
                                        value={tipDesc}
                                        onChange={handleTipDescChange}
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <Button loading={!loaded}>Search</Button>
                                </Form.Field>
                            </Form>
                        </Segment>
                    </Grid.Row>
                </Grid.Column>
                {tipWorkstations.length > 0 ? (
                    <Grid.Column>
                        <Grid.Row>
                            <Button
                                icon
                                onClick={handleDownloadClick}
                                labelPosition='left'
                                style={{ marginBottom: '12px' }}
                            >
                                <Icon name='download' />
                                Download this data
                            </Button>
                        </Grid.Row>
                        <Grid.Row>
                            <Segment floated='left' size='small'>
                                <Dimmer active={!loaded}>
                                    <Loader />
                                </Dimmer>
                                <Table
                                    singleLine
                                    striped
                                    selectable
                                    unstackable
                                    style={{ marginTop: 0, backgroundColor: '#f7f7f7' }}
                                    size='small'
                                >
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell style={{ backgroundColor: 'black', color: 'white' }}>Employee Id</Table.HeaderCell>
                                            <Table.HeaderCell style={{ backgroundColor: 'black', color: 'white' }}>ADP Position Id</Table.HeaderCell>
                                            <Table.HeaderCell style={{ backgroundColor: 'black', color: 'white' }}>Employee Name</Table.HeaderCell>
                                            <Table.HeaderCell style={{ backgroundColor: 'black', color: 'white' }}>Job Title</Table.HeaderCell>
                                            <Table.HeaderCell style={{ backgroundColor: 'black', color: 'white' }}>Date</Table.HeaderCell>
                                            <Table.HeaderCell style={{ backgroundColor: 'black', color: 'white' }}>Store Number</Table.HeaderCell>
                                            <Table.HeaderCell style={{ backgroundColor: 'black', color: 'white' }}>Tip</Table.HeaderCell>
                                            <Table.HeaderCell style={{ backgroundColor: 'black', color: 'white' }}>Tip Description</Table.HeaderCell>
                                            <Table.HeaderCell style={{ backgroundColor: 'black', color: 'white' }}>Hours</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {tipWorkstations.map(
                                            (workstation): JSX.Element => (
                                                <Table.Row key={workstation.id}>
                                                    <Table.Cell>{workstation.employeeNumber}</Table.Cell>
                                                    <Table.Cell>{workstation.externalPositionId}</Table.Cell>
                                                    <Table.Cell>{workstation.employeeName}</Table.Cell>
                                                    <Table.Cell>{workstation.employeeJobTitle}</Table.Cell>
                                                    <Table.Cell>{moment(workstation.date).format('MM/DD/YYYY')} </Table.Cell>
                                                    <Table.Cell>{workstation.cafeNumber}</Table.Cell>
                                                    <Table.Cell>{workstation.tips}</Table.Cell>
                                                    <Table.Cell>{workstation.tipDescription}</Table.Cell>
                                                    <Table.Cell>
                                                        {workstation.hours == null
                                                            ? '-'
                                                            : workstation.hours}
                                                    </Table.Cell>
                                                </Table.Row>
                                            )
                                        )}
                                    </Table.Body>
                                </Table>
                                <Pagination
                                    defaultActivePage={1}
                                    totalPages={totalPages}
                                    onPageChange={handlePageChange}
                                />
                            </Segment>
                        </Grid.Row>
                    </Grid.Column>
                ) : (
                    <Grid.Column>
                        <h3> No data to display - see filter options</h3>
                    </Grid.Column>
                )}
            </Grid>
        </>
    )
}

export default withRouter(EmpHoursDashboard)
