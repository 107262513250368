import Axios from 'axios'
import authHelper from '../utils/authHelper'
import { PaginatedList } from './tipOrderService'
import config from '../utils/config'
import handleResponseAuthorization from '../utils/responseHelper'

const baseUrl = config.API_BASE_URL
export interface OrderDetail {

  companyID: number
  id: number
  storE_NBR: number
  ordeR_NBR: number
  oriG_CLOSE_DTTM: Date
  starT_DTTM: Date
  closE_DTTM: Date
  firsT_DTTM: Date
  savE_DTTM: Date
  kitcheN_SENT_DTTM: Date
  kitcheN_BUMP_DTTM: Date
  businesS_DT: Date
  registeR_NBR: number
  state: number
  olD_STATE: number
  suB_STATE: number
  approveR_ID: number
  approveR_CORP_ID: number
  serveR_ID: number
  serveR_CORP_ID: number
  emP_PURCH_ID: number
  emP_PURCH_CORP_ID: number
  cashieR_ID: number
  cashieR_CORP_ID: number
  grilL_NBR: number
  spliT_FROM_ORD: number
  combineD_TO_ORD: number
  tax: number
  taX_EXEMPT: number
  taX_EXEMPT_REASON: number
  taX_ID: number
  neT_EXEMPT_TOTAL: number
  crediT_TIPS: number
  casH_TIPS: number
  linE_DISC_TOTAL: number
  iteM_REC_CNT: number
  neT_TOTAL: number
  suB_TOTAL: number
  destination: number
  traininG_MODE: number
  ziP_CODE: number
  filE_DTTM: Date
  inserT_DTTM: Date
  loaD_ID: number
  version: number
  updatE_DTTM: Date
  poS_ORDER_SOURCE: number
  srC_SYS_ORD_NBR: number

}

export interface OrderDetailSearchCriteria {
  startDate?: Date
  endDate?: Date
  pageNumber?: number
  panOrderId?: number
  storeNumber?: string
}


const defaultOrderDetailList: PaginatedList<OrderDetail> = {
  pageNumber: 0,
  totalPages: 0,
  data: [],
  hasPreviousPage: false,
  hasNextPage: false
}

const combineAvailableSearchParams = (
  searchCriteria: OrderDetailSearchCriteria
) => {
  const nameValuePairs = [
    {
      name: 'startDate',
      value: searchCriteria.startDate ? searchCriteria.startDate.toJSON() : null
    },
    {
      name: 'endDate',
      value: searchCriteria.endDate ? searchCriteria.endDate.toJSON() : null
    },
    { name: 'pageNumber', value: searchCriteria.pageNumber },
    { name: 'orderNumber', value: searchCriteria.panOrderId },
    {
      name: 'storeNumber',
      value:
        searchCriteria.storeNumber === 'All' ? 0 : searchCriteria.storeNumber
    }
  ]
  // Builds query string by removing any pairs with no value and then combining into one query string.
  // Query strings are in the form of `name1=value1&name2=value2...`
  return nameValuePairs
    .filter(entry => entry.value)
    .map(entry => `${entry.name}=${entry.value}`)
    .join('&')
}

const exportQuery = async (searchCriteria: OrderDetailSearchCriteria) => {
  const urlParams = combineAvailableSearchParams(searchCriteria)

  const url: string = urlParams
    ? `${baseUrl}/orderDetails/ExportSearch?${urlParams}`
    : `${baseUrl}/orderDetails/ExportSearch`

  try {
    const response = await Axios.get(`${url}`, {
      responseType: 'arraybuffer',
      headers: authHelper.authHeader()
    })
    const fileName = response.headers['x-tipr-filename']
    const fileType = response.headers['content-type']
    return { fileName, data: response.data, fileType }
  } catch (error) {
    handleResponseAuthorization(error)
    return Promise.reject(error)
  }
}
const searchOrderDetails = (searchCriteria: OrderDetailSearchCriteria): Promise<PaginatedList<OrderDetail>> => {

  const urlParams = combineAvailableSearchParams(searchCriteria)

  // no valid search parameters, do regular search
  // might want to return an error instead?
  const url = urlParams
    ? `${baseUrl}/orderDetails?${urlParams}`
    : `${baseUrl}/orderDetails`
  return Axios.get<PaginatedList<OrderDetail>>(url, {
    headers: authHelper.authHeader()
  })
    .then(response => response)
    .then(result => {
      console.log(result.data)
      return result.data
    })
    .catch(error => {
      console.log('caught in service')
      console.log(error)
      handleResponseAuthorization(error)
      throw error
    })
}

export default {
  exportQuery,
  searchOrderDetails,
  defaultOrderDetailList
 
}
