import React from 'react'
import { Segment, Container, Image } from 'semantic-ui-react'
import logoUrl from '../MicroconFooterLogo.png'
const Footer: React.FC = () => (
  <Segment inverted vertical size='tiny'>
    <Container textAlign='center'>
      <Image centered size='small' src={logoUrl} />
    </Container>
  </Segment>
)

export default Footer
