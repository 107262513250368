import React from 'react'
import { Route, Redirect, RouteProps } from 'react-router-dom'

export const PrivateRoute = ({ children, path, ...rest }: RouteProps) => {
  if (localStorage.getItem('user')) {
    return (
      <Route
        path={path}
        {...rest}
      >
        {children}
      </Route>
    )
  }
  return (
    <Route
      path={path}
      {...rest}
      render={({ location }) =>
        <Redirect
          to={{
            pathname: '/login',
            state: { from: location }
          }}
        />
      }
    />
  )
}
